@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@400&display=swap');

.LabelArea {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    padding: 0 15% 0 15%;
}

.LabelEndColumn {
    flex: 1 0 auto;
    font-family: 'Assistant', sans-serif;
    font-size: 1.2em;
    font-weight: 600;
    justify-content: flex-end;
}

.LabelMidColumn {
    content: "";
    width: 11%;
}

.DisplayArea {
    margin: 10px 0 10px 0;
    display: flex;
    justify-content: space-between;
    font-family: 'Assistant', sans-serif;
}

.DisplayLeftColumn {
    flex: 1 0 auto;
}

.DisplayMidColumn {
    flex: none;
    width: 12%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.DisplayRightColumn {
    flex: 1 0 auto;
}

.Center {
    border-radius: 5px;
    border: 1px solid #a7a7a7;
    height: 100px;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
}

.LeftEntryArea {
    margin-top: 8%;
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-end;
}

.RightEntryArea {
    margin-top: 8%;
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-start;
}

.PreferenceArea {
    display: flex;
    flex-direction: row;
}

.LeftPerson {
    padding-top: 8%;
    padding-right: 25%;
}

.LeftPreference {
    margin-left: 5px;
}

.RightPerson {
    padding-top: 8%;
    padding-left: 25%;
}

.RightPreference {
    margin-left: 5px;
}

.PersonSimInput {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #888888;
    width: 50px;
    white-space: nowrap;
    overflow: hidden;
}

.PreferenceSimInput {
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #C1C1C1;
    width: 60px;
    height: 25px;
    white-space: nowrap;
    overflow: hidden;
}