.BottomSimArea {
    display: flex;
    margin: 0 17% 0 17%;
    justify-content: space-between;
    margin-top: 50px;
}

.BottomLeftSimArea {
    display: flex;
    justify-content: flex-start;
    text-align: center;
}

.PlayButton {
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 10px;
    border: none;
    background-color: #59ca42;
    width: 80px;
    height: 25px;
    font-family: 'Assistant', sans-serif;
    font-size: 1em;
    color: white;
    
}

.PlayButton:hover {
    transition-duration: 0.4s;
    background-color: #4baa38;
}

.PauseButton {
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 10px;
    border: none;
    background-color: #428dca;
    width: 80px;
    height: 25px;
    font-family: 'Assistant', sans-serif;
    font-size: 1em;
    color: white;
}

.PauseButton:hover {
    transition-duration: 0.4s;
    background-color: #3866aa;
}

.RestartButton {
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 10px;
    border: none;
    background-color: #ec6d6d;
    width: 80px;
    height: 25px;
    font-family: 'Assistant', sans-serif;
    font-size: 1em;
    color: white;
    margin-left: 10px;
}

.RestartButton:hover {
    transition-duration: 0.4s;
    background-color: #da5757;
}

.SkipButton {
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 10px;
    border: none;
    background-color: #e7c554;
    width: 120px;
    height: 25px;
    font-family: 'Assistant', sans-serif;
    font-size: 1em;
    color: white;
    margin-left: 10px;
    
}

.SkipButton:hover {
    transition-duration: 0.4s;
    background-color: #ccae4d;
}


.BottomRightSimArea {
    display: flex;
    justify-content: flex-end;
}

.GoBackSimButton {
    border-radius: 10px;
    border: 1px solid #DADADA;
    background-color: #f0f0f0;
    width: 90px;
    height: 38px;
    font-family: 'Assistant', sans-serif;
    font-size: 1em;
    color: black;
}

.GoBackSimButton:hover {
    transition-duration: 0.4s;
    background-color: #D1D1D1;
}

.SliderArea {
    display: flex;
    justify-content: flex-start;
    margin: 5px 17% 0 17%;
}

.SliderNote {
    margin-left: 20px;
    font-family: 'Assistant', sans-serif;
    font-size: 1em;
}

.slider {
    margin-top: 15px;
    -webkit-appearance: none;
    width: 18%;
    height: 10px;
    border-radius: 5px;  
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
  }
  
  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 15px;
    height: 15px;
    border-radius: 50%; 
    background: #51b63c;
    cursor: pointer;
  }
  
  .slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #51b63c;
    cursor: pointer;
  }