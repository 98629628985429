.LeftEntryArea {
    margin-top: 8%;
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-end;
}

.RightEntryArea {
    margin-top: 8%;
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-start;
}

.PreferenceArea {
    display: flex;
    flex-direction: row;
}

.LeftPerson {
    padding-top: 8%;
    padding-right: 25%;
}

.LeftPreference {
    margin-right: 5px;
}

.RightPerson {
    padding-top: 8%;
    padding-left: 25%;
}

.RightPreference {
    margin-left: 5px;
}

.PersonInput {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #cecece;
    width: 50px;
    height: 18px;
}

.PreferenceInput {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid red;
    width: 50px;
    height: 15px;
}

