@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@400&display=swap');

.OptionsArea {
    display: flex;
    justify-content: center;
    margin: 15px 17% 0 17%;
}

.MidColumnArea {
    flex: 1;
    font-family: 'Assistant', sans-serif;
}

.EndColumnArea {
    flex: 1;
    font-family: 'Assistant', sans-serif;
    display: flex;
    justify-content: flex-end;
}

.NumMatchesInput {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #DADADA;
    width: 100px;
    margin-left: 20px;
}

.AboutButton {
    border-radius: 5px;
    border: 1px solid #DADADA;
    background-color: #f0f0f0;
    width: 80px;
    height: 38px;
    font-family: 'Assistant', sans-serif;
    font-size: 1em;
}

.AboutButton:hover {
    transition-duration: 0.4s;
    background-color: #D1D1D1;
}