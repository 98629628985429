@import url(https://fonts.googleapis.com/css2?family=Exo+2:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Assistant:wght@400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Assistant:wght@400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Assistant:wght@400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Assistant:wght@400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Assistant:wght@400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Assistant:wght@400&display=swap);
.Title {
    text-align: center;
    font-family: 'Exo 2', sans-serif;
    font-size: 1.3em;
    font-weight: 100;
}

.Subtitle {
    text-align: center;
    font-family: 'Assistant', sans-serif;
    font-size: 1em;
    margin-top: .5em;
    padding-bottom: .3em;
}

h1 {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    margin: 0 17% 0 17%;
}
h1:before, h1:after{
    content: "";
    -webkit-flex: 1 1;
            flex: 1 1;
    border-bottom: 1px solid;
    margin: auto;
}
h1:before {
    margin-right: 10px;
}
h1:after {
    margin-left: 10px;
}
.OptionsArea {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    margin: 15px 17% 0 17%;
}

.MidColumnArea {
    -webkit-flex: 1 1;
            flex: 1 1;
    font-family: 'Assistant', sans-serif;
}

.EndColumnArea {
    -webkit-flex: 1 1;
            flex: 1 1;
    font-family: 'Assistant', sans-serif;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}

.NumMatchesInput {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #DADADA;
    width: 100px;
    margin-left: 20px;
}

.AboutButton {
    border-radius: 5px;
    border: 1px solid #DADADA;
    background-color: #f0f0f0;
    width: 80px;
    height: 38px;
    font-family: 'Assistant', sans-serif;
    font-size: 1em;
}

.AboutButton:hover {
    transition-duration: 0.4s;
    background-color: #D1D1D1;
}
.LeftEntryArea {
    margin-top: 8%;
    margin-bottom: 20px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}

.RightEntryArea {
    margin-top: 8%;
    margin-bottom: 20px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
}

.PreferenceArea {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
}

.LeftPerson {
    padding-top: 8%;
    padding-right: 25%;
}

.LeftPreference {
    margin-right: 5px;
}

.RightPerson {
    padding-top: 8%;
    padding-left: 25%;
}

.RightPreference {
    margin-left: 5px;
}

.PersonInput {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #cecece;
    width: 50px;
    height: 18px;
}

.PreferenceInput {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid red;
    width: 50px;
    height: 15px;
}


.LabelArea {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin-top: 20px;
    padding: 0 15% 0 15%;
}

.LabelEndColumn {
    -webkit-flex: 1 0 auto;
            flex: 1 0 auto;
    font-family: 'Assistant', sans-serif;
    font-size: 1.2em;
    font-weight: 600;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}

.LabelMidColumn {
    content: "";
    width: 11%;
}

.DisplayArea {
    margin: 10px 0% 10px 0%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.DisplayLeftColumn {
    -webkit-flex: 1 0 auto;
            flex: 1 0 auto;
}

.DisplayMidColumn {
    -webkit-flex: none;
            flex: none;
    width: 10%;
}

.DisplayRightColumn {
    -webkit-flex: 1 0 auto;
            flex: 1 0 auto;
}

.BottomArea {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    width: 100%;
    margin-top: 25px;
}

.LeftBottomArea {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    padding-left: 17%;
}

.RightBottomArea {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    padding-right: 17%;
}

.SubmitButton {
    border-radius: 20px;
    border: none;
    background-color: #3d98ff;
    width: 180px;
    height: 38px;
    font-family: 'Assistant', sans-serif;
    font-size: 1em;
    color: white;
}

.SubmitButton:hover {
    transition-duration: 0.4s;
    background-color: #1c89ff;
}

.ResetButton {
    border-radius: 20px;
    border: 1px solid #dadada;
    background-color: #f0f0f0;
    width: 100px;
    height: 38px;
    font-family: 'Assistant', sans-serif;
    font-size: 1em;
    margin-right: 10px;
}

.ResetButton:hover {
    transition-duration: 0.4s;
    background-color: #d1d1d1;
}

.DemoButton {
    border-radius: 20px;
    border: none;
    background-color: #3d98ff;
    width: 100px;
    height: 38px;
    font-family: 'Assistant', sans-serif;
    font-size: 1em;
    margin-right: 10px;
    color: white;
}

.DemoButton:hover {
    transition-duration: 0.4s;
    background-color: #1c89ff;
}

.WarningArea {
    margin: 25px 17% 0 17%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    font-family: 'Assistant', sans-serif;
}

.WarningPaper {
    width: 60%;
    background-color: rgb(255, 219, 219);
    border-radius: 8px;
    padding: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: left;
}

.WarningPaperTitle {
    font-size: 1.2em;
    font-weight: 700;
}

.WarningPaperLine {
    border: 1px dashed grey;
}

.WarningPaperBody {
    font-size: 1em;
}
.BottomSimArea {
    display: -webkit-flex;
    display: flex;
    margin: 0 17% 0 17%;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin-top: 50px;
}

.BottomLeftSimArea {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    text-align: center;
}

.PlayButton {
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 10px;
    border: none;
    background-color: #59ca42;
    width: 80px;
    height: 25px;
    font-family: 'Assistant', sans-serif;
    font-size: 1em;
    color: white;
    
}

.PlayButton:hover {
    transition-duration: 0.4s;
    background-color: #4baa38;
}

.PauseButton {
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 10px;
    border: none;
    background-color: #428dca;
    width: 80px;
    height: 25px;
    font-family: 'Assistant', sans-serif;
    font-size: 1em;
    color: white;
}

.PauseButton:hover {
    transition-duration: 0.4s;
    background-color: #3866aa;
}

.RestartButton {
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 10px;
    border: none;
    background-color: #ec6d6d;
    width: 80px;
    height: 25px;
    font-family: 'Assistant', sans-serif;
    font-size: 1em;
    color: white;
    margin-left: 10px;
}

.RestartButton:hover {
    transition-duration: 0.4s;
    background-color: #da5757;
}

.SkipButton {
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 10px;
    border: none;
    background-color: #e7c554;
    width: 120px;
    height: 25px;
    font-family: 'Assistant', sans-serif;
    font-size: 1em;
    color: white;
    margin-left: 10px;
    
}

.SkipButton:hover {
    transition-duration: 0.4s;
    background-color: #ccae4d;
}


.BottomRightSimArea {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}

.GoBackSimButton {
    border-radius: 10px;
    border: 1px solid #DADADA;
    background-color: #f0f0f0;
    width: 90px;
    height: 38px;
    font-family: 'Assistant', sans-serif;
    font-size: 1em;
    color: black;
}

.GoBackSimButton:hover {
    transition-duration: 0.4s;
    background-color: #D1D1D1;
}

.SliderArea {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    margin: 5px 17% 0 17%;
}

.SliderNote {
    margin-left: 20px;
    font-family: 'Assistant', sans-serif;
    font-size: 1em;
}

.slider {
    margin-top: 15px;
    -webkit-appearance: none;
    width: 18%;
    height: 10px;
    border-radius: 5px;  
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    transition: opacity .2s;
  }
  
  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 15px;
    height: 15px;
    border-radius: 50%; 
    background: #51b63c;
    cursor: pointer;
  }
  
  .slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #51b63c;
    cursor: pointer;
  }
.LabelArea {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin-top: 20px;
    padding: 0 15% 0 15%;
}

.LabelEndColumn {
    -webkit-flex: 1 0 auto;
            flex: 1 0 auto;
    font-family: 'Assistant', sans-serif;
    font-size: 1.2em;
    font-weight: 600;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}

.LabelMidColumn {
    content: "";
    width: 11%;
}

.DisplayArea {
    margin: 10px 0 10px 0;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    font-family: 'Assistant', sans-serif;
}

.DisplayLeftColumn {
    -webkit-flex: 1 0 auto;
            flex: 1 0 auto;
}

.DisplayMidColumn {
    -webkit-flex: none;
            flex: none;
    width: 12%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.DisplayRightColumn {
    -webkit-flex: 1 0 auto;
            flex: 1 0 auto;
}

.Center {
    border-radius: 5px;
    border: 1px solid #a7a7a7;
    height: 100px;
    width: 200px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    background-color: white;
}

.LeftEntryArea {
    margin-top: 8%;
    margin-bottom: 20px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}

.RightEntryArea {
    margin-top: 8%;
    margin-bottom: 20px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
}

.PreferenceArea {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
}

.LeftPerson {
    padding-top: 8%;
    padding-right: 25%;
}

.LeftPreference {
    margin-left: 5px;
}

.RightPerson {
    padding-top: 8%;
    padding-left: 25%;
}

.RightPreference {
    margin-left: 5px;
}

.PersonSimInput {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #888888;
    width: 50px;
    white-space: nowrap;
    overflow: hidden;
}

.PreferenceSimInput {
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #C1C1C1;
    width: 60px;
    height: 25px;
    white-space: nowrap;
    overflow: hidden;
}
.AboutArea {
    margin: 15px 17% 0 17%;
    font-family: 'Assistant', sans-serif;
}

.GoBackArea {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}

.GoBackButton {
    border-radius: 5px;
    border: 1px solid #DADADA;
    background-color: #f0f0f0;
    width: 90px;
    height: 38px;
    font-family: 'Assistant', sans-serif;
    font-size: 1em;
    color: black;
}

.GoBackButton:hover {
    transition-duration: 0.4s;
    background-color: #D1D1D1;
}

.NoteArea {
    -webkit-flex-direction: column;
            flex-direction: column;
}

.NoteWrapperArea {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.NotePaper {
    width: 85%;
    background-color: rgb(240, 240, 240);
    border-radius: 6px;
    padding: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 25px;
    text-align: left;
}

.NotePaperTitle {
    font-size: 1.2em;
    font-weight: 700;
}

.NotePaperLine {
    border: 1px dashed grey;
}

.NotePaperBody {
    padding-top: 5px;
    font-size: 1em;
}

.FooterArea {
    margin: 6% 17% 20px 17%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
}

.FooterLine {
    border: none;
    height: 1px;
    color: rgb(182, 182, 182);
    background-color: rgb(192, 192, 192);
}

.FooterNote {
    font-family: 'Assistant', sans-serif;
    font-size: 0.8em;
    color: rgb(160, 160, 160);
}

a {
    color: rgb(50, 122, 255);
    text-decoration: none;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

