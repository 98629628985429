@import url('https://fonts.googleapis.com/css2?family=Exo+2:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@400&display=swap');

.Title {
    text-align: center;
    font-family: 'Exo 2', sans-serif;
    font-size: 1.3em;
    font-weight: 100;
}

.Subtitle {
    text-align: center;
    font-family: 'Assistant', sans-serif;
    font-size: 1em;
    margin-top: .5em;
    padding-bottom: .3em;
}

h1 {
    display: flex;
    flex-direction: row;
    margin: 0 17% 0 17%;
}
h1:before, h1:after{
    content: "";
    flex: 1 1;
    border-bottom: 1px solid;
    margin: auto;
}
h1:before {
    margin-right: 10px;
}
h1:after {
    margin-left: 10px;
}