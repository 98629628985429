@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@400&display=swap');

.LabelArea {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    padding: 0 15% 0 15%;
}

.LabelEndColumn {
    flex: 1 0 auto;
    font-family: 'Assistant', sans-serif;
    font-size: 1.2em;
    font-weight: 600;
    justify-content: flex-end;
}

.LabelMidColumn {
    content: "";
    width: 11%;
}

.DisplayArea {
    margin: 10px 0% 10px 0%;
    display: flex;
    justify-content: space-between;
}

.DisplayLeftColumn {
    flex: 1 0 auto;
}

.DisplayMidColumn {
    flex: none;
    width: 10%;
}

.DisplayRightColumn {
    flex: 1 0 auto;
}

.BottomArea {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 25px;
}

.LeftBottomArea {
    display: flex;
    justify-content: flex-start;
    padding-left: 17%;
}

.RightBottomArea {
    display: flex;
    justify-content: flex-end;
    padding-right: 17%;
}

.SubmitButton {
    border-radius: 20px;
    border: none;
    background-color: #3d98ff;
    width: 180px;
    height: 38px;
    font-family: 'Assistant', sans-serif;
    font-size: 1em;
    color: white;
}

.SubmitButton:hover {
    transition-duration: 0.4s;
    background-color: #1c89ff;
}

.ResetButton {
    border-radius: 20px;
    border: 1px solid #dadada;
    background-color: #f0f0f0;
    width: 100px;
    height: 38px;
    font-family: 'Assistant', sans-serif;
    font-size: 1em;
    margin-right: 10px;
}

.ResetButton:hover {
    transition-duration: 0.4s;
    background-color: #d1d1d1;
}

.DemoButton {
    border-radius: 20px;
    border: none;
    background-color: #3d98ff;
    width: 100px;
    height: 38px;
    font-family: 'Assistant', sans-serif;
    font-size: 1em;
    margin-right: 10px;
    color: white;
}

.DemoButton:hover {
    transition-duration: 0.4s;
    background-color: #1c89ff;
}

.WarningArea {
    margin: 25px 17% 0 17%;
    display: flex;
    justify-content: center;
    font-family: 'Assistant', sans-serif;
}

.WarningPaper {
    width: 60%;
    background-color: rgb(255, 219, 219);
    border-radius: 8px;
    padding: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: left;
}

.WarningPaperTitle {
    font-size: 1.2em;
    font-weight: 700;
}

.WarningPaperLine {
    border: 1px dashed grey;
}

.WarningPaperBody {
    font-size: 1em;
}